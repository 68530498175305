import { useState, useMemo, useRef, useCallback } from "react";
import { useApi } from "./useApi";
import { InlineDocsVerificationDevControls } from "../dev/InlineDocsVerificationDevControls";

type UseAwaitInlineDocsHook = {
  startPolling: () => void;
  cancelPolling: () => void;
  result: DocumentVerificationResult | null;
  /** A component that can be used in local and preview environments to force states of the document verification flow. */
  DevTools: () => JSX.Element;
};

export enum DocumentVerificationResult {
  /** Means we received the webhook, the user has finished uploading documents. */
  COMPLETE,
  /** Means we waited for <x>minutes and never got a webhook response. */
  CLIENT_TIMEOUT,
  /** The user dismissed the idv iframe */
  USER_CANCELLED,
  /** Something went wrong and we cannot recover. */
  ERROR,
}

/** The polling interval in `ms`. */
const USE_AWAIT_INLINE_DOCS_POLLING_INTERVAL = 3e3;
const CLIENT_TIMEOUT_THRESHOLD_MS = 3e5; // 5 minutes

export const useAwaitInlineDocs = (
  pollingIntervalMs: number = USE_AWAIT_INLINE_DOCS_POLLING_INTERVAL,
  clientTimeoutThresholdMs: number = CLIENT_TIMEOUT_THRESHOLD_MS,
): UseAwaitInlineDocsHook => {
  const isPolling = useRef(false);
  const { api } = useApi();
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<DocumentVerificationResult | null>(null);
  /** Track the time we are spending polling. If it exceeds our threshold, surface a client timeout result */
  const startTime = useRef<number>(0);
  const __devToolsForceClientTimeout = useRef<boolean>(false);

  const checkInlineDocs = useCallback(async () => {
    // If there is an error or polling has been stopped, abort.
    if (error || !isPolling.current) return;

    if (
      __devToolsForceClientTimeout.current === true ||
      performance.now() - startTime.current > clientTimeoutThresholdMs
    ) {
      isPolling.current = false;
      setResult(DocumentVerificationResult.CLIENT_TIMEOUT);
      return;
    }

    const inlineDocsResult = await api.resolveIdentityDocumentUrl();

    // If polling has been stopped since the last request was fired, abort.
    if (!isPolling.current) return;

    if (inlineDocsResult.isErr()) {
      setError(inlineDocsResult.error);
      setResult(DocumentVerificationResult.ERROR);
      return;
    }

    if (inlineDocsResult.value.consumed) {
      setResult(DocumentVerificationResult.COMPLETE);
    } else {
      setTimeout(() => {
        checkInlineDocs();
      }, pollingIntervalMs);
    }
  }, [
    api,
    clientTimeoutThresholdMs,
    error,
    __devToolsForceClientTimeout,
    pollingIntervalMs,
  ]);

  const startPolling = useCallback<
    UseAwaitInlineDocsHook["startPolling"]
  >(() => {
    if (isPolling.current) return;

    isPolling.current = true;
    startTime.current = performance.now();
    __devToolsForceClientTimeout.current = false;
    setResult(null);
    checkInlineDocs();
  }, [checkInlineDocs]);

  const cancelPolling = useCallback<
    UseAwaitInlineDocsHook["cancelPolling"]
  >(() => {
    isPolling.current = false;
    startTime.current = 0;
    setResult(DocumentVerificationResult.USER_CANCELLED);
  }, []);

  const hookValue = useMemo<UseAwaitInlineDocsHook>(
    () => ({
      startPolling,
      cancelPolling,
      result,
      DevTools: () => (
        <InlineDocsVerificationDevControls
          result={result}
          onForceClientTimeout={() => {
            __devToolsForceClientTimeout.current = true;
          }}
          onForceComplete={() => {
            isPolling.current = false;
            setResult(DocumentVerificationResult.COMPLETE);
          }}
          onForceError={() => {
            isPolling.current = false;
            setError("Forced");
            setResult(DocumentVerificationResult.ERROR);
          }}
        />
      ),
    }),
    [result, startPolling, cancelPolling],
  );

  return hookValue;
};
