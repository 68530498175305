import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Navigator } from "./Navigator";
import { PostMessageLogger } from "./PostMessageLogger";
import { useAutoSignup } from "./hooks/useAutoSignup";
import { PostMessageLoggerContextProvider } from "./contexts/PostMessageLoggerContextProvider";
import { useRouter } from "../hooks/useRouter";
import { Routes, UserStatus } from "../types";

const views = {
  Navigator: <Navigator />,
  Logger: <PostMessageLogger />,
};

export const DevControls = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { navigate } = useRouter();
  const [currentView, setCurrentView] =
    useState<keyof typeof views>("Navigator");

  // This statement prevents this entire module from being bundled in our production builds
  if (!(import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV)) {
    return;
  }

  // this is okay because this will only be rendered in dev/preview
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { autoSignup, autoSignupInFlight } = useAutoSignup();

  // this is okay because this will only be rendered in dev/preview
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed],
  );

  const runAutomatedSignup = (options?: { badUser?: boolean }) => {
    (async () => {
      const badUser = Boolean(options && options.badUser);
      const signupResult = await autoSignup({ badUser });

      if (signupResult.isErr()) {
        throw new Error("Signup failed");
      }

      // eslint-disable-next-line no-console
      console.log(`Onboarded user
email: ${signupResult.value.email}
password: ${signupResult.value.password}
status: ${signupResult.value.userStatus}
`);

      if (signupResult.value.userStatus === UserStatus.ACTIVE) {
        navigate(Routes.Summary);
      } else {
        navigate(Routes.ManualReview, { userStatus: UserStatus.IN_REVIEW });
      }
    })();
  };

  return (
    <div
      className={twMerge(
        "fixed bottom-2 right-2 h-[480px] w-[320px] overflow-hidden rounded-sm bg-[#8b8b8b]",
        collapsed && "h-[32px] w-[32px] rounded-full bg-yellow-400",
      )}
    >
      {collapsed ? (
        <div
          className="flex h-full w-full cursor-pointer items-center justify-center"
          onClick={toggleCollapsed}
        >
          <div className="w-[18px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z" />
            </svg>
          </div>
        </div>
      ) : (
        <>
          <header className="bg-yellow-400 px-2 py-1 text-xs font-bold text-yellow-800 dark:bg-yellow-500">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="size-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.5 8a3.5 3.5 0 0 0 3.362-4.476c-.094-.325-.497-.39-.736-.15L12.099 5.4a.48.48 0 0 1-.653.033 8.554 8.554 0 0 1-.879-.879.48.48 0 0 1 .033-.653l2.027-2.028c.24-.239.175-.642-.15-.736a3.502 3.502 0 0 0-4.476 3.427c.018.99-.133 2.093-.914 2.7l-5.31 4.13a2.015 2.015 0 1 0 2.828 2.827l4.13-5.309c.607-.78 1.71-.932 2.7-.914L11.5 8ZM3 13.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                DevTools
              </div>

              <div onClick={toggleCollapsed} className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5"
                >
                  <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                </svg>
              </div>
            </div>
          </header>

          {/* Nav */}
          <div className="my-2 px-2">
            <div className="flex items-center gap-2 rounded-xl border border-neutral-500 p-2 text-sm">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  {Object.keys(views).map((viewName) => (
                    <div
                      key={viewName}
                      className={twMerge(
                        "cursor-pointer text-sm font-bold opacity-60",
                        currentView === viewName && "opacity-100",
                      )}
                      onClick={setCurrentView.bind(
                        this,
                        viewName as keyof typeof views,
                      )}
                    >
                      {viewName}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="px-2">
            <div className="flex items-center gap-2 rounded-xl border border-neutral-500 p-2 text-sm">
              <div className="flex items-center gap-1">
                <div className="w-[10px] fill-[#3a3b3c]">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288l111.5 0L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7l-111.5 0L349.4 44.6z" />
                  </svg>
                </div>
                Auto-signup
              </div>
              <div>
                <button
                  disabled={autoSignupInFlight}
                  onClick={() => {
                    runAutomatedSignup();
                  }}
                  className="rounded-lg bg-neutral-500 px-2 py-0.5 text-xs text-neutral-800 transition-all hover:bg-neutral-600 hover:text-white disabled:bg-neutral-600 disabled:text-white disabled:opacity-65"
                >
                  Good user
                </button>
              </div>
              <div>
                <button
                  disabled={autoSignupInFlight}
                  onClick={() => {
                    runAutomatedSignup({ badUser: true });
                  }}
                  className="rounded-lg bg-neutral-500 px-2 py-0.5 text-xs text-neutral-800 transition-all hover:bg-neutral-600 hover:text-white disabled:bg-neutral-600 disabled:text-white disabled:opacity-65"
                >
                  Bad user
                </button>
              </div>
            </div>
          </div>

          {/* Outlet content */}
          <div className="h-full overflow-auto px-4 py-2">
            <PostMessageLoggerContextProvider>
              {views[currentView]}
            </PostMessageLoggerContextProvider>
          </div>

          {autoSignupInFlight && (
            <div className="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center bg-[#29093e]/30 align-middle text-white backdrop-blur-sm">
              ...doing work...
            </div>
          )}
        </>
      )}
    </div>
  );
};
