import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RouterContext } from "./RouterContext";
import {
  CurrentRoute,
  OnboardingAppRenderContext,
  OnboardingContextValue,
  RouterContextValue,
} from "../types";
import { Posthog, TelemetryEvents } from "@tigris/common";

export type RouterContextProviderProps = {
  routes: Array<{ path: string; element: ReactElement }>;
  initialRoute: CurrentRoute;
  /**
   * The mode the Onboarding application is running in.
   *
   * @default BREAKOUT_FROM_TRANSFER
   */
  renderContext?: OnboardingContextValue["renderContext"];
};

export const RouterContextProvider = ({
  routes,
  children,
  initialRoute,
  renderContext = OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER,
}: PropsWithChildren<RouterContextProviderProps>) => {
  const [history, setHistory] = useState<Array<CurrentRoute>>([initialRoute]);
  const [currentRoute, setCurrentRoute] = useState<CurrentRoute>(initialRoute);
  const [navigationDisabled, setNavigationDisabled] = useState(false);

  // Track initial page view
  useEffect(() => {
    Posthog.capture(TelemetryEvents.onboardingPageView, {
      pathname: currentRoute.pathname,
      renderContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useCallback<RouterContextValue["navigate"]>(
    (pathname, state, reset = false) => {
      const newRoute = { pathname, state };
      setCurrentRoute(newRoute);
      setHistory((prevHistory) =>
        reset ? [newRoute] : [...prevHistory, newRoute],
      );

      Posthog.capture(TelemetryEvents.onboardingPageView, {
        pathname,
        renderContext,
      });
    },
    [renderContext],
  );

  const canGoBack = useMemo(() => history.length > 1, [history]);

  const goBack = useCallback<RouterContextValue["goBack"]>(() => {
    if (!canGoBack || navigationDisabled) return;

    const target = history[history.length - 2];
    setCurrentRoute(target);
    setHistory((prevHistory) => {
      return prevHistory.slice(0, -1);
    });
  }, [canGoBack, history, navigationDisabled]);

  const enableNavigation = useCallback<
    RouterContextValue["enableNavigation"]
  >(() => {
    setNavigationDisabled(false);
  }, []);

  const disableNavigation = useCallback<
    RouterContextValue["disableNavigation"]
  >(() => {
    setNavigationDisabled(true);
  }, []);

  const contextValue = useMemo<RouterContextValue>(() => {
    const matchingElement = routes.find(
      ({ path }) => path === currentRoute.pathname,
    )?.element;

    if (!matchingElement) {
      throw new Error(`No element found for route: ${currentRoute.pathname}`);
    }

    return {
      currentRoute,
      navigate,
      outlet: matchingElement,
      goBack,
      canGoBack,
      enableNavigation,
      disableNavigation,
      navigationDisabled,
    };
  }, [
    canGoBack,
    currentRoute,
    disableNavigation,
    enableNavigation,
    goBack,
    navigate,
    navigationDisabled,
    routes,
  ]);

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  );
};
