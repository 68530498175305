import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CobrandingLogo } from "@tigris/mesokit";
import { Routes } from "../types";
import { useRouter } from "../hooks/useRouter";
import { useOnboarding } from "../hooks/useOnboarding";
import { twMerge } from "tailwind-merge";

const currentStepMapping = new Map<string, number>([
  [Routes.CreateAccount, 2],
  [Routes.PhoneEntry, 3],
  [Routes.Phone2Fa, 4],
  [Routes.BasicInfoOverview, 5],
  [Routes.BasicInfoEntry, 6],
  [Routes.TaxpayerIdEntry, 7],
  [Routes.SelectPaymentMethod, 8],
  [Routes.AddPaymentCard, 8],
  [Routes.Summary, 9],
]);

const allowedRoutes = [
  ...currentStepMapping.keys(),
  Routes.HandoffFailed,
  Routes.ManualReview,
];

export const Navigation = () => {
  const { currentRoute, goBack, canGoBack, navigationDisabled } = useRouter();
  const {
    configuration: { partner },
  } = useOnboarding();

  const currentStep = currentStepMapping.get(currentRoute.pathname) || 0;
  const totalSteps = currentStepMapping.size;
  const width = (currentStep / totalSteps) * 100 + "%";
  const progressComplete = currentStep === totalSteps;
  const backButtonClasses = twMerge(
    canGoBack ? "cursor-pointer opacity-100" : "",
    !canGoBack || navigationDisabled ? "cursor-default opacity-30" : "",
  );

  return (
    <AnimatePresence mode="wait">
      <nav className="flex h-10 flex-col justify-between gap-1">
        <div className="flex w-full items-center justify-between dark:text-white">
          <FontAwesomeIcon
            onClick={goBack}
            icon={icon({
              name: "arrow-left",
              style: "regular",
            })}
            className={backButtonClasses}
          />
          <AnimatePresence>
            {allowedRoutes.includes(currentRoute.pathname) && (
              <motion.div layoutId="CoBranding">
                <CobrandingLogo
                  displayName={partner?.displayName}
                  logoUri={partner?.logoUri}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <a
            className="block"
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.meso.network"
          >
            <FontAwesomeIcon
              icon={icon({
                name: "life-ring",
                style: "regular",
              })}
              className="opacity-60"
            />
          </a>
        </div>
        <div className="relative w-full">
          <div className="h-[2px] overflow-hidden rounded-sm bg-neutral-200/50 dark:bg-neutral-700">
            <motion.div
              layout
              initial={{ width: "0%", opacity: 0 }}
              animate={{
                width,
                opacity: width === "0%" ? 0 : 1,
              }}
              transition={{ ease: "anticipate", duration: 1 }}
              className="bg-highlight h-full"
            />
          </div>
          <AnimatePresence>
            {progressComplete && (
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  rotate: -12,
                  transition: { delay: 0.5 },
                }}
                exit={{ scale: 0.5, opacity: 0 }}
                className="absolute -top-3 right-0 rounded-full bg-neutral-100 dark:bg-neutral-800"
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: "circle-check",
                    style: "solid",
                  })}
                  className="text-violet-400"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
    </AnimatePresence>
  );
};
