import { FormField, GenericError, Routes } from "../types";
import {
  Button,
  LabelledInput,
  AddressInputProps,
  Suggestion,
  AddressInput,
  ErrorMessages,
} from "@tigris/mesokit";
import {
  ChangeEvent,
  FormEventHandler,
  useCallback,
  useRef,
  useState,
} from "react";
import { AddResidentialAddressInput } from "../generated/sdk";
import { z } from "zod";
import {
  dateOfBirthSchema,
  firstAndLastNameSchema,
  taxpayerIdLast4Schema,
} from "../utils/validation";
import { formatDateString, formatTaxpayerIdLast4 } from "../utils/formatting";
import { toast } from "sonner";
import {
  defaultFormField,
  isPOBoxError,
  isProhibitedRegionError,
  Posthog,
  rawAddressContainsPOBox,
  streetAddressSchema,
  TelemetryEvents,
} from "@tigris/common";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { getNextOnboardingStep } from "../utils/getNextOnboardingStep";
import { useRouter } from "../hooks/useRouter";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { AutoFocusRef } from "../utils/autoFocusRef";

enum ToastIds {
  TOAST_ID = "BasicInfoEntry",
  PO_BOX_NOT_ALLOWED = "po_box_not_allowed",
  PROHIBITED_REGION = "prohibited_region",
}
const FORM_ID = "BasicInfoEntry";

type FormState = {
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  fields: {
    taxpayerIdLast4: FormField;
    dateOfBirth: FormField;
    firstName: FormField;
    lastName: FormField;
    streetAddress: FormField<AddResidentialAddressInput>;
  };
};

type BasicInfoEntryFormState = FormState & {
  errors: Partial<Record<keyof FormState["fields"], GenericError>>;
};

const defaultStreetAddress: AddResidentialAddressInput = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  city: "",
  region: "",
  postalCode: "",
  countryCode: "",
};

const defaultFormState: BasicInfoEntryFormState = {
  isValid: false,
  isDirty: false,
  isTouched: false,
  fields: {
    firstName: defaultFormField(""),
    lastName: defaultFormField(""),
    dateOfBirth: defaultFormField(""),
    streetAddress: defaultFormField(defaultStreetAddress),
    taxpayerIdLast4: defaultFormField(""),
  },
  errors: {},
};

export const BasicInfoEntry = () => {
  const { navigate } = useRouter();
  const { api } = useApi();
  const { user, updateUser, configuration } = useOnboarding();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] =
    useState<BasicInfoEntryFormState>(defaultFormState);
  const taxpayerIdLast4Ref = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const submitOnboardingInfoResult = await api.resolveSubmitOnboardingInfo({
        basicInfoInput: {
          firstName: formState.fields.firstName.value,
          lastName: formState.fields.lastName.value,
          dateOfBirth: new Date(formState.fields.dateOfBirth.value)
            .toISOString()
            .split("T")[0],
        },
        residentialAddressInput: {
          ...formState.fields.streetAddress.value,
        },
        taxpayerIdLast4Input: {
          taxpayerIdLast4: formState.fields.taxpayerIdLast4.value,
        },
      });

      if (submitOnboardingInfoResult.isErr()) {
        setIsLoading(false);
        toast.error(submitOnboardingInfoResult.error, {
          id: ToastIds.TOAST_ID,
        });
        return;
      }

      const nextStep = getNextOnboardingStep(
        Routes.BasicInfoEntry,
        submitOnboardingInfoResult.value.addTaxpayerIdLast4,
        configuration.supportedPaymentMethods,
        user.status,
      );

      if (nextStep.isErr()) {
        setIsLoading(false);
        toast.error(nextStep.error, { id: ToastIds.TOAST_ID });
        return;
      }

      updateUser({
        firstName: formState.fields.firstName.value,
        lastName: formState.fields.lastName.value,
        residentialAddress: formState.fields.streetAddress.value,
      });

      navigate(nextStep.value.pathname);
    },
    [
      api,
      configuration.supportedPaymentMethods,
      formState.fields.dateOfBirth.value,
      formState.fields.firstName.value,
      formState.fields.lastName.value,
      formState.fields.streetAddress.value,
      formState.fields.taxpayerIdLast4.value,
      navigate,
      updateUser,
      user.status,
    ],
  );

  const onAddressResolved = useCallback<AddressInputProps["onAddressResolved"]>(
    (suggestion: Suggestion | null, rawInputValue: string) => {
      if (!suggestion) {
        // If we can't create a full `Suggestion`, we still have the raw input which we can validate for the existence of a PO Box
        if (rawAddressContainsPOBox(rawInputValue)) {
          toast.error(ErrorMessages.address.PO_BOX_NOT_ALLOWED_ERROR, {
            id: ToastIds.PO_BOX_NOT_ALLOWED,
          });
        } else {
          toast.dismiss(ToastIds.PO_BOX_NOT_ALLOWED);
        }

        setFormState((previousState) => {
          return {
            ...previousState,
            isValid: false,
            fields: {
              ...previousState.fields,
              streetAddress: {
                value: { ...defaultStreetAddress },
                isValid: false,
                isDirty:
                  previousState.fields.streetAddress.isDirty ||
                  suggestion !== null ||
                  rawInputValue.length > 0,
                isTouched: true,
              },
            },
          };
        });
        return;
      }

      const mappedSuggestion: AddResidentialAddressInput = {
        address1: suggestion.streetLine,
        address2: suggestion.secondary,
        city: suggestion.city,
        region: suggestion.state,
        postalCode: suggestion.zipcode,
        countryCode: "US",
      };

      const addressValidationResult =
        streetAddressSchema.safeParse(mappedSuggestion);
      const isValid = addressValidationResult.success;

      if (!isValid) {
        Posthog.capture(TelemetryEvents.formInputInvalid, {
          formId: FORM_ID,
          inputId: "streetAddress",
        });

        if (isPOBoxError(addressValidationResult.error)) {
          toast.error(ErrorMessages.address.PO_BOX_NOT_ALLOWED_ERROR, {
            id: ToastIds.PO_BOX_NOT_ALLOWED,
          });
        }

        if (isProhibitedRegionError(addressValidationResult.error)) {
          Posthog.capture(TelemetryEvents.onboardingProhibitedRegion, {
            formId: FORM_ID,
            inputId: "streetAddress",
            region: mappedSuggestion.region,
          });
          toast.error(
            ErrorMessages.basicInfoEntry.PROHIBITED_US_AND_TERRITORY_CODE,
            { id: ToastIds.PROHIBITED_REGION },
          );
        }
      } else {
        toast.dismiss(ToastIds.PROHIBITED_REGION);
        toast.dismiss(ToastIds.PO_BOX_NOT_ALLOWED);
      }

      setFormState((previousState) => ({
        ...previousState,
        isValid:
          isValid &&
          Object.entries(previousState.fields)
            .filter(([key, _]) => key !== "streetAddress")
            .every(([_, { isValid }]) => isValid),
        fields: {
          ...previousState.fields,
          streetAddress: {
            ...previousState.fields.streetAddress,
            isValid,
            isDirty: true,
            isTouched: true,
            value: mappedSuggestion,
          },
        },
      }));
    },
    [],
  );

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  const onBlur = useCallback(
    (fieldName: keyof FormState["fields"]) => () => {
      setFormState((previousState) => ({
        ...previousState,
        isTouched: true,
        isValid: Object.values(previousState.fields).every(
          (field) => field.isValid,
        ),
        isDirty: Object.values(previousState.fields).every(
          (field) => field.isDirty,
        ),
        fields: {
          ...previousState.fields,
          [fieldName]: {
            ...previousState.fields[fieldName],
            isTouched: true,
          },
        },
      }));
    },
    [],
  );

  const onFocus = useCallback((fieldName: string) => {
    return () => {
      Posthog.capture(TelemetryEvents.formInputFocus, {
        formId: FORM_ID,
        inputId: fieldName,
      });
    };
  }, []);

  const onChange = useCallback(function onChange<
    T = string | AddResidentialAddressInput,
  >({
    fieldName,
    format,
    validationSchema,
  }: {
    fieldName: keyof FormState["fields"];
    format?: (props: { previousValue: T; newValue: T }) => T;
    /**
     * A [Zod](https://zod.dev) schema to perform validation via `safeParse`.
     */
    validationSchema: z.ZodSchema;
  }) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      Posthog.capture(TelemetryEvents.formInputChange, {
        formId: FORM_ID,
        inputId: fieldName,
      });
      setFormState((previousState) => {
        const newValue =
          typeof format === "function"
            ? format({
                previousValue: previousState.fields[fieldName].value as T,
                newValue: event.target.value as T,
              })
            : event.target.value;

        const isDirty =
          previousState.fields[fieldName].isTouched ||
          newValue !== previousState.fields[fieldName].value;

        let isValid = false;
        let updatedErrors: BasicInfoEntryFormState["errors"] =
          previousState.errors;

        const result = validationSchema.safeParse(newValue);

        if (result.success === false) {
          updatedErrors = {
            ...previousState.errors,
            [fieldName]: { message: result.error.issues[0].message },
          };
        }

        isValid = result.success;
        if (!isValid) {
          Posthog.capture(TelemetryEvents.formInputInvalid, {
            formId: FORM_ID,
            inputId: fieldName,
          });
        }

        return {
          ...previousState,
          errors: updatedErrors,
          isDirty: previousState.isDirty || isDirty,
          isValid:
            isValid &&
            Object.entries(previousState.fields)
              .filter(([key, _]) => key !== fieldName)
              .every(([_, { isValid }]) => isValid),
          fields: {
            ...previousState.fields,
            [fieldName]: {
              ...previousState.fields[fieldName],
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    };
  }, []);

  return (
    <AnimationContainer onAnimationComplete={AutoFocusRef(taxpayerIdLast4Ref)}>
      <form
        id="BasicInfoEntry"
        name="BasicInfoEntry"
        onSubmit={onSubmit}
        className="onboarding-inner-content"
        data-testid="form"
        role="form"
      >
        <Heading title="Personal Identification" />
        <div className="flex gap-2">
          <LabelledInput
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            labelProps={{ text: "Social Security" }}
            name="taxpayerIdLast4"
            placeholder="Last 4-digits"
            value={formState.fields.taxpayerIdLast4.value}
            isValid={renderFieldAsValid("taxpayerIdLast4")}
            disabled={isLoading}
            onFocus={onFocus("taxpayerIdLast4")}
            onChange={onChange({
              fieldName: "taxpayerIdLast4",
              format: formatTaxpayerIdLast4,
              validationSchema: taxpayerIdLast4Schema,
            })}
            onBlur={onBlur("taxpayerIdLast4")}
            ref={taxpayerIdLast4Ref}
            data-testid="ssn-input"
          />
          <LabelledInput
            labelProps={{ text: "Date of Birth" }}
            placeholder="MM/DD/YYYY"
            name="dateOfBirth"
            value={formState.fields.dateOfBirth.value}
            isValid={renderFieldAsValid("dateOfBirth")}
            disabled={isLoading}
            onFocus={onFocus("dateOfBirth")}
            onChange={onChange<string>({
              fieldName: "dateOfBirth",
              format: formatDateString,
              validationSchema: dateOfBirthSchema,
            })}
            onBlur={onBlur("dateOfBirth")}
          />
        </div>
        <div className="flex gap-2">
          <LabelledInput
            labelProps={{ text: "First Name" }}
            name="firstName"
            value={formState.fields.firstName.value}
            isValid={renderFieldAsValid("firstName")}
            disabled={isLoading}
            onFocus={onFocus("firstName")}
            onChange={onChange<string>({
              fieldName: "firstName",
              validationSchema: firstAndLastNameSchema,
            })}
            onBlur={onBlur("firstName")}
            maxLength={255}
          />
          <LabelledInput
            labelProps={{ text: "Last Name" }}
            name="lastName"
            value={formState.fields.lastName.value}
            isValid={renderFieldAsValid("lastName")}
            disabled={isLoading}
            onFocus={onFocus("lastName")}
            onChange={onChange<string>({
              fieldName: "lastName",
              validationSchema: firstAndLastNameSchema,
            })}
            onBlur={onBlur("lastName")}
            maxLength={255}
          />
        </div>
        <AddressInput
          labelText="Residential Address"
          placeholder="123 Main Street"
          onFocus={onFocus("streetAddress")}
          onAddressResolved={onAddressResolved}
          disabled={isLoading}
          isValid={renderFieldAsValid("streetAddress")}
          inputName="streetAddress"
        />
        <div className="onboarding-footer">
          <Button
            key="BasicInfoEntry:button"
            disabled={!formState.isValid || isLoading}
            type="submit"
            isLoading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
