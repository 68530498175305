import { Button, Input } from "@tigris/mesokit";
import { FormEventHandler, useCallback, useRef, useState } from "react";
import {
  Value,
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
// eslint-disable-next-line no-duplicate-imports
import PhoneInput from "react-phone-number-input";
import { toast } from "sonner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { AutoFocusRef } from "../utils/autoFocusRef";
import { Posthog, TelemetryEvents } from "@tigris/common";

// Empty component to allow us to use the "advanced" version of PhoneInput.
const Noop = () => <></>;

const TOAST_ID = "PhoneEntry";
const FORM_ID = TOAST_ID;
const INPUT_ID = "phoneInput";

export const PhoneEntry = () => {
  const { navigate } = useRouter();
  const {
    api: { resolveAddPhoneNumber },
  } = useApi();
  const { updateUser } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<{
    value?: Value;
    isValid: boolean;
    isDirty: boolean;
  }>({
    value: "",
    isValid: false,
    isDirty: false,
  });
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const handleAddPhoneNumber = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const addPhoneNumberResult = await resolveAddPhoneNumber({
        input: { phoneNumber: phoneNumber.value! },
      });

      setIsLoading(false);

      if (addPhoneNumberResult.isErr()) {
        toast.error(addPhoneNumberResult.error, { id: TOAST_ID });
      } else {
        updateUser({ phoneNumber: formatPhoneNumber(phoneNumber.value!) });
        navigate(Routes.Phone2Fa);
      }
    },
    [navigate, phoneNumber.value, resolveAddPhoneNumber, updateUser],
  );

  return (
    <AnimationContainer onAnimationComplete={AutoFocusRef(phoneInputRef)}>
      <form
        id={FORM_ID}
        name={FORM_ID}
        onSubmit={handleAddPhoneNumber}
        className="onboarding-inner-content"
      >
        <Heading
          title="Two-Factor Authentication"
          subtitle="Two-factor authentication adds an extra layer of security to your
        account."
        />
        <section className="flex flex-col gap-1">
          <div className="flex items-center rounded-xl bg-neutral-100 dark:bg-neutral-700">
            <div className="pointer-events-none flex h-12 items-center px-3 text-neutral-500 dark:text-neutral-300">
              🇺🇸 (+1)
            </div>
            <div className="grow">
              <PhoneInput
                name={INPUT_ID}
                id={INPUT_ID}
                placeholder="Your Phone Number"
                defaultCountry="US"
                country="US"
                smartCaret={true}
                value={phoneNumber.value}
                inputComponent={Input}
                countryCallingCodeEditable={false}
                international={false}
                countrySelectComponent={Noop}
                limitMaxLength={true}
                data-testid="phone-input"
                isValid={
                  phoneNumber.isValid ||
                  !(phoneNumber.isDirty && !phoneNumber.isValid)
                }
                // @ts-expect-error: `react-phone-number-input` does not provide a type declaration for this, but it resolves to an `HTMLInputElement`.
                ref={phoneInputRef}
                onChange={(value) => {
                  Posthog.capture(TelemetryEvents.formInputChange, {
                    formId: FORM_ID,
                    inputId: INPUT_ID,
                  });
                  setPhoneNumber((state) => {
                    const isValid =
                      value === "" || value === undefined
                        ? false
                        : isValidPhoneNumber(value as string);
                    const isDirty =
                      state.isValid === false && state.isDirty === true
                        ? false
                        : (value && value.length === 12) ||
                          (!state.isDirty && isValid);

                    if (isDirty && !isValid) {
                      Posthog.capture(TelemetryEvents.formInputInvalid, {
                        formId: FORM_ID,
                        inputId: INPUT_ID,
                      });
                    }

                    return {
                      value,
                      isValid,
                      isDirty,
                    };
                  });
                }}
                onFocus={() =>
                  Posthog.capture(TelemetryEvents.formInputFocus, {
                    formId: FORM_ID,
                    inputId: INPUT_ID,
                  })
                }
              />
            </div>
          </div>
          <span className="text-xs opacity-60 dark:text-white">
            <FontAwesomeIcon
              icon={icon({ name: "globe", style: "light" })}
              className="mr-1"
            />
            Meso is available in the U.S. and will be launching in other
            countries soon.
          </span>
        </section>
        <div className="mt-auto">
          <Button
            key="PhoneEntry:button"
            disabled={isLoading || !phoneNumber.isValid}
            type="submit"
            isLoading={isLoading}
          >
            Send Code
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
