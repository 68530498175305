import { AuthCode, AuthCodeRef, ErrorMessages } from "@tigris/mesokit";
import { useCallback, useMemo, useRef, useState } from "react";
import { TwoFactorMethod } from "../generated/sdk";
import { toast } from "sonner";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { getNextOnboardingStep } from "../utils/getNextOnboardingStep";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { AutoFocusRef } from "../utils/autoFocusRef";

const TOAST_ID = "Phone2Fa";

export const Phone2Fa = () => {
  const { navigate } = useRouter();
  const {
    api: { resolveSend2FACode, resolveVerifyPhoneNumber, resolveUser },
  } = useApi();
  const { user, configuration, updateUser } = useOnboarding();
  const authCodeRef = useRef<AuthCodeRef>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = useCallback(() => {
    setIsLoading(false);
    authCodeRef.current?.clear();
    toast.error(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR, {
      id: TOAST_ID,
    });

    setTimeout(() => {
      authCodeRef.current?.focus();
    });
  }, [setIsLoading, authCodeRef]);

  const verifyPhoneChallenge = useCallback(
    async (verificationCode: string) => {
      setIsLoading(true);

      const verifyPhoneNumberResult = await resolveVerifyPhoneNumber({
        input: { verificationCode },
      });

      if (verifyPhoneNumberResult.isErr()) {
        handleError();
        return;
      }

      // Once we have verified the user's 2nd factor, we can get the user ID from the backend to store in our context
      const userResult = await resolveUser();

      if (userResult.isErr()) {
        handleError();
        return;
      }

      const updatedUser = userResult.value;
      updateUser({ id: updatedUser.id });

      const nextStep = getNextOnboardingStep(
        Routes.Phone2Fa,
        verifyPhoneNumberResult.value,
        configuration.supportedPaymentMethods,
        user.status,
      );

      if (nextStep.isErr()) {
        toast.error(nextStep.error, { id: TOAST_ID });
        return;
      }

      navigate(nextStep.value.pathname, nextStep.value.state);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAuthCodeChange = useMemo(
    () => async (result: string) => {
      if (result.length === 6) {
        await verifyPhoneChallenge(result);
      }
    },
    [verifyPhoneChallenge],
  );

  const sendCode = useCallback(async () => {
    const send2FACodeResult = await resolveSend2FACode({
      input: { method: TwoFactorMethod.PHONE },
    });

    if (send2FACodeResult.isErr()) {
      setIsLoading(false);
      authCodeRef.current?.clear();
      toast.error(send2FACodeResult.error, { id: TOAST_ID });
    } else {
      setIsLoading(false);
      setTimeout(() => authCodeRef.current?.focus());
    }
  }, [resolveSend2FACode]);

  return (
    <AnimationContainer
      onAnimationComplete={AutoFocusRef<AuthCodeRef>(authCodeRef)}
    >
      <form id="Phone2Fa" name="Phone2Fa" className="onboarding-inner-content">
        <Heading
          title="Verify Phone Number"
          subtitle={`Enter the code sent to ${user.phoneNumber}`}
        />
        <AuthCode
          disabled={isLoading}
          onAuthCodeChange={onAuthCodeChange}
          ref={authCodeRef}
          onResend={sendCode}
        />
      </form>
    </AnimationContainer>
  );
};
