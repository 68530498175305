import { motion } from "framer-motion";
import { motionVariants } from "../utils/animation";
import {
  Button,
  Title,
  Text,
  Logo,
  CustomToastError,
  Confirmation,
} from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCallback, useEffect, useState } from "react";
import {
  OnboardingAppRenderContext,
  OnboardingContextValue,
  UrlString,
} from "../types";
import { twMerge } from "tailwind-merge";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

import {
  DocumentVerificationResult,
  useAwaitInlineDocs,
} from "../hooks/useAwaitInlineDocs";
import { toast } from "sonner";
import { useOnboarding } from "../hooks/useOnboarding";
import { Posthog, TelemetryEvents } from "@tigris/common";

export type DocumentVerificationProps = {
  identityDocumentUrl: UrlString;
  onReturnToTransfer: OnboardingContextValue["returnToTransfer"];
};

const TOAST_ID = "documentVerificationResult:error";

const SuccessBadge = () => {
  return (
    <motion.div
      data-testid="DocumentVerification:successBadge"
      className="flex items-center gap-4 rounded-2xl p-4 shadow-md dark:bg-neutral-700"
      {...motionVariants.fadeInDown}
    >
      <FontAwesomeIcon
        icon={icon({ name: "check", style: "regular" })}
        className="text-green-500"
      />
      <div className="text-sm">
        Thanks! You have successfully uploaded your documents. You will hear
        from us soon.
      </div>
    </motion.div>
  );
};

export const DocumentVerification = ({
  identityDocumentUrl,
  onReturnToTransfer,
}: DocumentVerificationProps) => {
  const [showIframe, setShowIframe] = useState(false);
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
  const {
    startPolling,
    cancelPolling,
    result: documentVerificationResult,
    DevTools,
  } = useAwaitInlineDocs();
  const { renderContext } = useOnboarding();

  const launchIframe = useCallback(() => {
    toast.dismiss(TOAST_ID);
    setShowIframe(true);
    Posthog.capture(TelemetryEvents.onboardingInlineDocsVerificationStart);
  }, []);

  // Once the iframe reports as loaded, start polling
  const handleIframeLoaded = useCallback(startPolling, [startPolling]);

  // Handle the user manually dismissing the iframe
  const cancelIframe = useCallback(() => {
    setShowIframe(false);
    cancelPolling();
  }, [cancelPolling]);

  useEffect(() => {
    if (documentVerificationResult === DocumentVerificationResult.ERROR) {
      setShowIframe(false);
      setShowTimeoutDialog(false);
      toast.error(
        <CustomToastError
          title="Something went wrong"
          body={
            <div>
              Please{" "}
              <a
                href="https://support.meso.network"
                target="_blank"
                rel="noreferrer"
                className="underline opacity-80 transition-opacity hover:opacity-100"
              >
                contact support
              </a>
              .
            </div>
          }
        />,
        {
          id: TOAST_ID,
        },
      );
    }

    if (
      documentVerificationResult ===
        DocumentVerificationResult.CLIENT_TIMEOUT &&
      !showTimeoutDialog
    ) {
      setShowTimeoutDialog(true);
    }

    if (documentVerificationResult === DocumentVerificationResult.COMPLETE) {
      setShowIframe(false);
      Posthog.capture(TelemetryEvents.onboardingInlineDocsVerificationComplete);
    }
  }, [documentVerificationResult, showTimeoutDialog]);

  useEffect(() => {
    Posthog.capture(
      TelemetryEvents.onboardingInlineDocsVerificationComponentRender,
    );
  }, []);

  return (
    <>
      <motion.div
        data-testid="DocumentVerification:root"
        className={twMerge(
          "flex h-full flex-col",
          showIframe ? "pointer-events-none" : "",
        )}
        {...motionVariants.fadeIn}
      >
        <div className="mb-2 mt-4">
          <Title.Medium bold>Document Verification</Title.Medium>
          <Text>
            Unfortunately, we could not verify your identity with the details
            you provided and your account is under review.
          </Text>
        </div>
        {documentVerificationResult !== DocumentVerificationResult.ERROR && (
          <Text className="mt-2">
            Use the button below to upload additional documentation and we will
            get back to you with a decision as soon as we can.
          </Text>
        )}

        <div className="mt-auto flex w-full flex-col items-center gap-2">
          {documentVerificationResult ===
            DocumentVerificationResult.COMPLETE && <SuccessBadge />}
          <div className="w-full">
            {documentVerificationResult === DocumentVerificationResult.ERROR ||
            documentVerificationResult ===
              DocumentVerificationResult.COMPLETE ? (
              <>
                {/*
                  If we are onboarding via the iframe integration (inline/embedded), render a button to return the user to the original window.
                  Otherwise, we are in standalone and can simply tell the user they can close the window via the success badge.
                */}
                {renderContext ===
                OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER ? (
                  <Button
                    static
                    className="w-full"
                    onClick={() => {
                      onReturnToTransfer("onboardingTerminated");
                    }}
                    data-testid="DocumentVerification:returnToTransferButton"
                  >
                    Close this window
                  </Button>
                ) : (
                  <Text
                    className="mt-6 text-center font-bold"
                    data-testid="DocumentVerification:closeWindowText"
                  >
                    You may now close this window.
                  </Text>
                )}
              </>
            ) : (
              <Button
                disabled={showIframe}
                onClick={launchIframe}
                data-testid="DocumentVerification:launchButton"
              >
                Upload Additional Documents
              </Button>
            )}
          </div>
        </div>
      </motion.div>
      <Dialog
        open={showIframe}
        // Pass an empty function here to prevent closing the dialog by clicking outside of its bounds.
        onClose={() => {}}
        className="relative z-50"
      >
        <DialogBackdrop
          className="fixed inset-0 bg-neutral-400/20 duration-300 ease-out data-[closed]:opacity-0"
          transition
        />
        <DialogPanel
          className="delay-50 fixed left-0 top-0 flex h-screen w-screen flex-col px-2 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          transition
        >
          {/* Header */}
          <div className="flex items-center justify-between p-2">
            <button
              className="flex cursor-pointer items-center gap-2 rounded-full bg-neutral-300 px-3 py-2 text-xs"
              onClick={cancelIframe}
              data-testid="DocumentVerification:cancelButton"
            >
              <span>←</span> <Logo size="sm" showText={false} />{" "}
              <span>Back to Meso</span>
            </button>
            <a
              className="block"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.meso.network"
            >
              <FontAwesomeIcon
                icon={icon({
                  name: "life-ring",
                  style: "regular",
                })}
                className="text-neutral-700 opacity-60 dark:text-neutral-300"
              />
            </a>
          </div>

          {/* Frame content */}
          <div className="w-full flex-1 rounded-t-2xl bg-white p-2">
            <iframe
              data-testid="DocumentVerification:iframe"
              src={identityDocumentUrl}
              id="idv-frame"
              className="h-full w-full"
              frameBorder={0}
              allow="geolocation; microphone; camera;"
              onLoad={handleIframeLoaded}
            />
          </div>

          <Confirmation
            open={showTimeoutDialog}
            iconName="clock"
            body="Are you still working on verifying your docs?"
            acceptButtonText="I'm still working"
            rejectButtonText="No"
            onResult={(status) => {
              if (status === "accepted" || status === "dismissed") {
                setShowTimeoutDialog(false);
                startPolling();
              } else {
                cancelPolling();
                setShowTimeoutDialog(false);
                setShowIframe(false);
              }
            }}
          />
        </DialogPanel>
        <DevTools />
      </Dialog>
    </>
  );
};
