import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";
import { CurrentRoute, Routes, UserStatus } from "../types";
import { InstrumentKind, LoginFragment } from "../generated/sdk";

type ProfileStatus = LoginFragment["profileStatus"];

const onboardingSteps: Array<
  [keyof Omit<ProfileStatus, "__typename">, Routes]
> = [
  ["acceptedMesoTerms", Routes.Root],
  ["acceptedZeroHashTerms", Routes.Root],
  ["account", Routes.CreateAccount],
  ["phone", Routes.PhoneEntry],
  ["phoneVerified", Routes.PhoneEntry],
  ["basicInfo", Routes.BasicInfoOverview],
  ["residentialAddress", Routes.BasicInfoOverview],
  ["taxpayerId", Routes.BasicInfoOverview],
  ["financialInstrument", Routes.SelectPaymentMethod],
];

/**
 * Determine next navigation step based on current user & app state.
 */
export const getNextOnboardingStep = (
  route: string,
  profileStatus: ProfileStatus,
  supportedPaymentMethods: InstrumentKind[],
  userStatus?: UserStatus | string,
): Result<CurrentRoute, string> => {
  // Return hardcoded route transitions
  if (route === Routes.PhoneEntry) {
    return ok({ pathname: Routes.Phone2Fa });
  } else if (route === Routes.BasicInfoOverview) {
    return ok({ pathname: Routes.BasicInfoEntry });
  } else if (route === Routes.BasicInfoEntry && !profileStatus.taxpayerId) {
    return ok({ pathname: Routes.TaxpayerIdEntry });
  }

  // Find the next step based on the ordered `onboardingSteps` constant
  const next = onboardingSteps.find(([stepName]) => !profileStatus[stepName]);
  if (next && next[0] === "financialInstrument") {
    // Special case for determining which financialInstrument component to show based on `supportedPaymentMethods`
    return ok({
      pathname:
        supportedPaymentMethods.length == 1 &&
        supportedPaymentMethods[0] === InstrumentKind.PAYMENT_CARD
          ? Routes.AddPaymentCard
          : Routes.SelectPaymentMethod,
    });
  } else if (next) {
    // Return the singular next component
    return ok({ pathname: next[1] });
  }

  // If all `onboardingSteps` are done (i.e. `true`) and the user is `NEW`, show `Summary`
  if (userStatus === UserStatus.NEW) {
    return ok({ pathname: Routes.Summary });
  }

  // All `onboardingSteps` are done, but the user is not `NEW`, return error since we shouldn't get here
  return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
};
